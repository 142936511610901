import React from 'react'

const InsightsOverview = () => {
  return (
    <>
      <div className='what_industry_section'>
        <div className='industries_sections'>
          <ul>
            <li>Automotive</li>
            <li>Industrial Manufacturing</li>
            <li>Transportation and Logistics</li>
            <li>Metals and Mining</li>
            <li>Chemicals</li>
            <li>Retail</li>
          </ul>
        </div>
        <div className='industries_sections'>
          <ul>
            <li>Banking and Finance</li>
            <li>Healthcare</li>
            <li>Telecommunications</li>
            <li>E-commerce</li>
            <li>Pharmaceuticals and Life Science</li>
            <li>Gas and Oil</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default InsightsOverview
