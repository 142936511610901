import React, { Fragment } from 'react'
import { Routes, Route } from 'react-router-dom'
import HomeSlider from '../Components/SliderHomepage/HomeSlider.jsx'
import Insight from '../Screen/Insight/Insight.jsx'
import Careers from '../Screen/Careers/Careers.jsx'
import ContactUS from '../Screen/ContactUs/Contact.jsx'
import Privacy from '../Screen/Privacy/Privacy.jsx'
import Cookies from '../Screen/Cookies/Cookies.jsx'
import Disclaimer from '../Screen/Disclaimer/Disclaimer.jsx'
import Securitys from '../Screen/Securitys/Securitys.jsx'
import WhoWeAre from '../Screen/WhoWeAre/WhoWeAre.jsx'
import WhatWeDo from '../Screen/WhatWeDo/WhatWeDo.jsx'
import SAP from '../Pages/SAP/SAP.jsx'
import AI from '../Pages/AI/AI.jsx'
import Workday from '../Pages/Workday/Workday.jsx'
import Appian from '../Pages/Appian/Appian.jsx'
import CloudComputing from '../Pages/CloudComputing/CloudComputing.jsx'

const MainRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route exact path='/' element={<HomeSlider />} />
        <Route exact path='/who-we-are' element={<WhoWeAre />} />
        <Route exact path='/what-we-do' element={<WhatWeDo />} />
        <Route exact path='/sap' element={<SAP />} />
        <Route exact path='/ai' element={<AI />} />
        <Route exact path='/workday' element={<Workday />} />
        <Route exact path='/appian' element={<Appian />} />
        <Route exact path='/cloud-computing' element={<CloudComputing />} />
        <Route exact path='/insights' element={<Insight />} />
        <Route exact path='/careers' element={<Careers />} />
        <Route exact path='/contact-us' element={<ContactUS />} />
        <Route exact path='/privacy' element={<Privacy />} />
        <Route exact path='/cookie' element={<Cookies />} />
        <Route exact path='/disclaimer' element={<Disclaimer />} />
        <Route exact path='/security' element={<Securitys />} />
      </Routes>
    </Fragment>
  )
}

export default MainRoutes
