import * as React from 'react'
import './Navbar.css'
import NavbarPoper from './NavbarPoper/NavbarPoper'
import { Link, NavLink } from 'react-router-dom'
import WhoweareSection from './WhoweareSection/WhoweareSection'
import Insights from '../../Components/Navbar/Insights/Insights'
import Company from '../../Components/Navbar/Company/Company.jsx'
import Industry from '../../Components/Navbar/Company/Industry.jsx'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuIcon from '@mui/icons-material/Menu'
import { Close } from '@mui/icons-material'
// import InsightsOverview from '../../Screen/Header/Insights/InsightsOverview/InsightsOverview'

function Navbar () {
  // const [open, setOpen] = React.useState(false)
  // const [whoweare, setwhoweare] = React.useState(false)
  const [insights, setinsights] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const [menu, setMenu] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const width = window.innerWidth
  console.log('width', expanded)

  // const whatWeDo = () => {
  //   setOpen(true)
  //   setwhoweare(false)
  //   setinsights(false)
  // }

  // const whoWeAre = () => {
  //   setOpen(false)
  //   setwhoweare(true)
  //   setinsights(false)
  // }

  // const insightsHandeler = () => {
  //   setinsights(true)
  //   setOpen(false)
  //   setwhoweare(false)
  // }

  return (
    <>
      {width < 780 ? (
        <div>
          <div className='menu_slider'>
            {menu ? (
              <Close onClick={() => setMenu(!menu)} />
            ) : (
              <MenuIcon onClick={() => setMenu(!menu)} />
            )}
          </div>
          {menu && (
            <div>
              <Accordion
                expanded={
                  expanded === 'panel1' ||
                  expanded === 'panel44' ||
                  expanded === 'panel45' ||
                  expanded === 'panel46' ||
                  expanded === 'panel47'
                }
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography sx={{ flexShrink: 0 }}>What we Do</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion
                    expanded={expanded === 'panel44'}
                    onChange={handleChange('panel44')}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel44bh-content'
                      id='panel44bh-header'
                    >
                      <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Overview
                      </Typography>
                    </AccordionSummary>
                    {/* <AccordionDetails>
                      <Typography>
                        Nunc vitae orci ultricies, auctor nunc in, volutpat
                        nisl. Integer sit amet egestas eros, vitae egestas
                        augue. Duis vel est augue.
                      </Typography>
                    </AccordionDetails> */}
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'panel45'}
                    onChange={handleChange('panel45')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel45bh-content'
                      id='panel45bh-header'
                    >
                      <Typography sx={{ flexShrink: 0 }}>Industries</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul className='ul_list'>
                        <li>Banking</li>
                        <li>Capital Markets</li>
                        <li>Consumer Goods and Distribution</li>
                        <li>Communications,Media, and Information Services</li>
                        <li>Education</li>
                        <li>Energy,Resources, and Utilities</li>
                        <li>Healthcare</li>
                        <li>High Tech</li>
                        <li>Insurance</li>
                        <li>Life Sciences</li>
                        <li>Manufacturing</li>
                        <li>Public Services</li>
                        <li>Retail</li>
                        <li>Travel and Logistics</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'panel46'}
                    onChange={handleChange('panel46')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel46bh-content'
                      id='panel46bh-header'
                    >
                      <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Services
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul className='ul_list'>
                        <li>Cloud</li>
                        <li>Cognative Business Operations</li>
                        <li>Consulting</li>
                        <li>Cybersecurity</li>
                        <li>Data and Analytics</li>
                        <li>Enterprise Solutions</li>
                        <li>IoT and Digital Engineering</li>
                        <li>Brethair Interactive</li>
                        <li>Sustainability Services</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'panel47'}
                    onChange={handleChange('panel47')}
                  >
                    <AccordionSummary
                      aria-controls='panel47bh-content'
                      id='panel47bh-header'
                    >
                      <Typography sx={{ flexShrink: 0 }}>
                        Products and Platforms
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'panel47'}
                    onChange={handleChange('panel47')}
                  >
                    <AccordionSummary
                      aria-controls='panel47bh-content'
                      id='panel47bh-header'
                    >
                      <Typography sx={{ flexShrink: 0 }}>
                        Research and Innovation
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2bh-content'
                  id='panel2bh-header'
                >
                  <Typography sx={{ flexShrink: 0 }}>Who we are</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className='ul_list'>
                    <Link className='link_item' to='/overview'>
                      Overview
                    </Link>
                    <Link className='link_item' to='/aboutus'>
                      About Us
                    </Link>
                    <Link className='link_item' to='/leadership'>
                      Leadership
                    </Link>
                    <Link className='link_item' to='/news'>
                      In the News
                    </Link>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel4bh-content'
                  id='panel4bh-header'
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Personal data
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                    Integer sit amet egestas eros, vitae egestas augue. Duis vel
                    est augue.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      ) : (
        <nav>
          <div className='logo'>
            <img src='/Images/logo.jpeg' alt='Logo' />
            {/* <h6>Breathair</h6> */}
          </div>
          <ul
            className='nav-links'
            // onMouseLeave={() => setOpen(false)}
          >
            <NavLink to='/' className='menu_items'>
              <div className='menu_click_over'>
                Who we do
                {/* <WhoweareSection /> */}
                <NavbarPoper />
              </div>
            </NavLink>
            <NavLink to='/who-we-are' className='menu_items'>
              <div className='menu_click_over'>
                Who we are
                <WhoweareSection />
              </div>
            </NavLink>
            <NavLink to='/industry' className='menu_items'>
              <div className='menu_click_over'>
                Industry
                <Industry />
              </div>
            </NavLink>
            <NavLink to='/insights' className='menu_items'>
              <div className='menu_click_over'>
                Insights
                <Insights />
              </div>
            </NavLink>
            <NavLink to='/company' className='menu_items'>
              <div className='menu_click_over'>
                Company
                <Company />
              </div>
            </NavLink>
            <NavLink to='/careers' className='menu_items'>
              Careers
            </NavLink>
          </ul>
          {/* {open && <NavbarPoper opens={setOpen} />} */}
          {/* {whoweare && <WhoweareSection opener={setwhoweare} />} */}
          {insights && <Insights insighter={setinsights} />}
        </nav>
      )}
    </>
  )
}

export default Navbar
