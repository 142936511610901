import React from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import FaqContent from './FaqContent/FaqContent'
import CoreMember from './CoreMember/CoreMember.jsx'

const WhoWeAre = () => {
  return (
    <div>
      <CoreMember />
      <FaqContent />
    </div>
  )
}

export default WhoWeAre
