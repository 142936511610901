import * as React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CompanyPopup from '../../../Screen/Header/Company/CompanyDetail/CompanyPopup.jsx'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

// function a11yProps (index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`
//   }
// }

export default function Insights ({ insighter }) {
  // const [value, setValue] = React.useState(0)
  // const handleClose = () => insighter(false)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }

  return (
    <div
      className='nav_toppers'
      // onMouseLeave={handleClose}
    >
      <Box
        sx={{
          flexGrow: 1,
          // bgcolor: 'background.paper',
          background: '#1c1b1c',
          marginTop: '4rem',
          // background: 'red',
          color: 'white',
          display: 'flex',
          width: 'auto',
          height: 'auto',
          // padding: '3% 0% 1% 5%',
          padding: '40px',
          borderRadius: '17px'
        }}
      >
        <CompanyPopup />
      </Box>
    </div>
  )
}
