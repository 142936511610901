import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import './Navbarpoper.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import WhatOverview from '../../../Screen/HomeOverview/WhatOverview'
import WhatIndustry from '../../../Screen/WhatIndustry/WhatIndustry'
import WhatServices from '../../../Screen/Header/WhatServices/WhatServices'
import WhatProducts from '../../../Screen/Header/WhatProducts/WhatProducts'

const styles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  textTransform: 'none',
  borderStyle: 'none none solid none',
  borderWidth: '1px',
  paddingLeft: 0,
  paddingRight: 0,
  marginRight: 0,
  marginLeft: 0,
  borderColor: '#000000',
  width: 250
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

export default function NavbarPoper () {
  const [value, setValue] = React.useState(0)
  // const handleClose = () => opens(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className='nav_topper'>
      <Box
        sx={{
          flexGrow: 1,
          background: '#1c1b1c',
          width: '80vw',
          color: 'white',
          display: 'flex',
          height: '60vh',
          padding: '3% 0% 1% 5%',
          borderRadius: '17px',
          marginTop: '4rem'
        }}
      >
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textTransform: 'none'
          }}
        >
          <Tab
            label={
              <>
                <p className='tab_para_name' style={{ color: '#FFFFFF' }}>
                  Overview
                </p>{' '}
                <ArrowForwardIosIcon className='tab_icon_arrow' />
              </>
            }
            {...a11yProps(0)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p className='tab_para_name'>Industries</p>
                <ArrowForwardIosIcon
                  className='tab_icon_arrow'
                  // sx={{ transform: 'scale(0.5)' }}
                />
              </>
            }
            {...a11yProps(1)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p className='tab_para_name'>Services</p>
                <ArrowForwardIosIcon
                  className='tab_icon_arrow'
                  //  sx={{ transform: 'scale(0.5)' }}
                />
              </>
            }
            {...a11yProps(2)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p className='tab_para_name'>Products and Platforms</p>
                <ArrowForwardIosIcon
                  className='tab_icon_arrow'
                  // sx={{ transform: 'scale(0.5)' }}
                />
              </>
            }
            {...a11yProps(3)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p className='tab_para_name'>Research and Innovation</p>
                <ArrowForwardIosIcon
                  className='tab_icon_arrow'
                  // sx={{ transform: 'scale(0.5)' }}
                />
              </>
            }
            {...a11yProps(4)}
            sx={styles}
          />
          {/* <Tab label='Item Six' {...a11yProps(5)} sx={styles} />
          <Tab label='Item Seven' {...a11yProps(6)} sx={styles} /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <WhatOverview />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WhatIndustry />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WhatServices />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <WhatProducts />
        </TabPanel>
        <TabPanel value={value} index={4}>
          Item Five
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
        </TabPanel>
        <TabPanel value={value} index={6}>
          Item Seven
        </TabPanel>
      </Box>
    </div>
  )
}
