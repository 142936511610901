import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import './HomeSlider.css'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import Crousel from './Crousel/Crousel'

const HomeSlider = () => {
  return (
    <>
      <div className='crousel_container'>
        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          showIndicators={true}
          stopOnHover={true}
          autoFocus={true}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          dots={true}
        >
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img1.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img2.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img3.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img4.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img5.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img6.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img7.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img8.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
          <div className='crousel_main_container'>
            {/* </div> */}
            <h2 className='srousel_heading'>Inventing for Impact</h2>
            <div
              className='crousel_sub_container'
              style={{ backgroundImage: `URL('Images/img1.jpg')` }}
            ></div>
            <p className='content_para'>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
              deleniti placeat dolor asperiores architecto quasi quis nostrum ad
              nihil adipisci?
            </p>
            <button className='know_more'>Know More</button>
          </div>
        </Carousel>
      </div>
      <div className='who_we_are'>
        <div
          className='main_backgroun_img'
          style={{ backgroundImage: `url('./Images/Home/whoweare.jpg')` }}
        >
          <div className='main_content'>
            <p className='who_we_are_heading'>WHO WE ARE</p>
            <h2 className='who_we_are_content'>
              We build greater futures through innovation and collective
              knowledge.
            </h2>
            <p className='who_we_are_desc'>
              Brethair is an IT services, consulting and business solutions
              organization that has been partnering with many of the world’s
              largest businesses in their transformation journeys for over 50
              years.
            </p>
            <p className='get_to_know_us'>
              Get to know us{' '}
              <ArrowCircleRightIcon sx={{ transform: 'scale(1.9)' }} />{' '}
            </p>
          </div>
        </div>
      </div>
      <div className='who_we_are'>
        <div
          className='main_backgroun_imgs'
          style={{ backgroundImage: `url('./Images/Home/whatwedo.jpg')` }}
        >
          <div className='main_content'>
            <p className='who_we_are_heading'>WHAT WE DO</p>
            <h2 className='who_we_are_content'>
              Brethair transforms businesses through technology.
            </h2>
            <p className='who_we_are_desc'>
              We help businesses successfully navigate digital transformation
              and drive real growth, drawing on the combined power of experience
              and contextual knowledge, across a vast ecosystem of expertise.
            </p>
            <p className='get_to_know_us'>
              Get to know us{' '}
              <ArrowCircleRightIcon sx={{ transform: 'scale(1.9)' }} />{' '}
            </p>
          </div>
        </div>
      </div>

      <Crousel />
      <br />
      <br />
      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export default HomeSlider
