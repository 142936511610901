import React from 'react'

const WhatProducts = () => {
  return (
    <>
      <div className='what_industry_section'>
        <div className='industries_sections'>
          <ul>
            <li>ADD</li>
            <li>BANCS</li>
            <li>BFSI Platform</li>
            <li>CHORMA</li>
            <li>Customer Intelligence</li>
            <li>ERP on Cloud</li>
          </ul>
        </div>
        <div className='industries_sections'>
          <ul>
            <li>iON</li>
            <li>HOBS</li>
            <li>Intelligent Urban Exchange</li>
            <li>OmniStore</li>
            <li>Optumera</li>
          </ul>
        </div>
        <div className='industries_sections'>
          <ul>
            <li>TCP TAP</li>
            <li>The Smart Ledgers</li>
            <li>MasterCraft</li>
            <li>Jile</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default WhatProducts
