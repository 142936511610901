import * as React from 'react'
import PropTypes from 'prop-types'
// import Tabs from '@mui/material/Tabs'
// import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
// import './Navbarpoper.css'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// import WhatOverview from '../../../Screen/HomeOverview/WhatOverview'
// import WhatIndustry from '../../../Screen/WhatIndustry/WhatIndustry'
// import WhatServices from '../../../Screen/Header/WhatServices/WhatServices'
// import WhatProducts from '../../../Screen/Header/WhatProducts/WhatProducts'
// import WhoOverview from '../../../Screen/Header/WhoweareSections/WhoweareOverview/WhoOverview'
import InsightsOverview from '../../../Screen/Header/Insights/InsightsOverview/InsightsOverview'

// const styles = {
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   textTransform: 'none',
//   borderStyle: 'none none solid none',
//   borderWidth: '1px',
//   paddingLeft: 0,
//   paddingRight: 0,
//   marginRight: 0,
//   marginLeft: 0,
//   borderColor: '#000000',
//   width: 250
// }

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

// function a11yProps (index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`
//   }
// }

export default function Insights ({ insighter }) {
  // const [value, setValue] = React.useState(0)
  // const handleClose = () => insighter(false)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }

  return (
    <div
      className='nav_toppers'
      // onMouseLeave={handleClose}
    >
      <Box
        sx={{
          flexGrow: 1,
          // bgcolor: 'background.paper',
          background: '#1c1b1c',
          marginTop: '4rem',
          // background: 'red',
          color: 'white',
          display: 'flex',
          width: 'auto',
          height: 'auto',
          // padding: '3% 0% 1% 5%',
          padding: '40px',
          borderRadius: '17px'
        }}
      >
        {/* <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // textDecoration:"normal",
            textTransform: 'none'
            // border: '2px solid green'
          }}
        >
          <Tab
            label={
              <>
                <p>Overview</p>{' '}
                <ArrowForwardIosIcon sx={{ transform: 'scale(0.5)' }} />
              </>
            }
            {...a11yProps(0)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p>Customer Stories</p>
                <ArrowForwardIosIcon sx={{ transform: 'scale(0.5)' }} />
              </>
            }
            {...a11yProps(1)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p>Perspective</p>
                <ArrowForwardIosIcon sx={{ transform: 'scale(0.5)' }} />
              </>
            }
            {...a11yProps(2)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p>Global Studies</p>
                <ArrowForwardIosIcon sx={{ transform: 'scale(0.5)' }} />
              </>
            }
            {...a11yProps(3)}
            sx={styles}
          />
          <Tab
            label={
              <>
                <p>Topics</p>
                <ArrowForwardIosIcon sx={{ transform: 'scale(0.5)' }} />
              </>
            }
            {...a11yProps(3)}
            sx={styles}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <InsightsOverview />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WhatIndustry />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WhatServices />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <WhatProducts />
        </TabPanel> */}

        <InsightsOverview />
      </Box>
    </div>
  )
}
