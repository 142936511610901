import React from 'react'
// import './sap.css'

const Appian = () => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='tech_container'>
        <div className='left_tech_container'>
          <h2 className='left_tech_container_heading'>
            Appian: Low-code platform streamlining business app development,
            accelerating digital transformation with rapid deployment and
            minimal coding.
          </h2>
          <p className='left_container_para'>
            Appian, a leading low-code automation platform, revolutionizes
            application development with an intuitive interface. Its visual
            design tools empower both technical and non-technical users to
            swiftly build sophisticated applications. This accelerates digital
            transformation, fostering agility across industries.
          </p>
          <p className='left_container_para'>
            Appian's commitment to integration and scalability ensures seamless
            connectivity, facilitating a smooth transition and maximizing
            current investments. It offers a powerful solution for rapid
            application development and robust process automation, providing a
            competitive edge in today's dynamic business environment.
          </p>
        </div>
        <div
          className='right_tech_container'
          style={{ backgroundImage: `URL('Images/img1.jpg')` }}
        ></div>
      </div>
      <div className='small_tech_container'>
        <h1>Benefit of Implementing Appian</h1>
        <div className='smalls_tech_container'>
          <div className='small_tech_card'>
            <img src='/Images/SAP/enterprise.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Business Process Automation (BPM)
            </h5>
            <p className='small_card_para_tech'>
              Appian is widely used to streamline and automate complex business
              processes, reducing manual effort and improving efficiency
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/supply-chain.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Case Management</h5>
            <p className='small_card_para_tech'>
              It enables organizations to manage and optimize case workflows,
              ensuring that tasks are handled efficiently and in accordance with
              established processes.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/customer.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Customer Relationship Management (CRM)
            </h5>
            <p className='small_card_para_tech'>
              Appian can be employed to build custom CRM solutions, providing a
              unified platform for managing customer interactions, sales, and
              support processes.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/human.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Supply Chain Management</h5>
            <p className='small_card_para_tech'>
              Appian helps in optimizing and automating supply chain processes,
              from procurement and inventory management to order fulfillment and
              logistics.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/finance.png' alt='finance' srcSet='' />
            <h5 className='small_card_para_head'>
              Financial Services Compliance
            </h5>
            <p className='small_card_para_tech'>
              In the financial sector, Appian is used for compliance management,
              ensuring that regulatory requirements are met through automated
              processes and workflows.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/products.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Healthcare Process Automation
            </h5>
            <p className='small_card_para_tech'>
              Appian can assist in automating healthcare workflows, such as
              patient onboarding, claims processing, and regulatory compliance.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/bussiness.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Human Resources (HR) Automation
            </h5>
            <p className='small_card_para_tech'>
              It facilitates the automation of HR processes, including employee
              onboarding, performance management, and leave requests.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/manufacture.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Risk and Compliance Management
            </h5>
            <p className='small_card_para_tech'>
              Appian is utilized to create applications that monitor and manage
              risk, ensuring organizations comply with industry regulations and
              standards.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/government.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Quality Assurance and Testing
            </h5>
            <p className='small_card_para_tech'>
              Appian can be employed to automate testing processes, helping
              organizations ensure the quality and reliability of their
              applications.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/ecommerce.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Project Management</h5>
            <p className='small_card_para_tech'>
              It supports the creation of project management applications,
              allowing teams to collaborate, track progress, and manage tasks in
              a centralized environment.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/oilgas.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              IT Service Management (ITSM)
            </h5>
            <p className='small_card_para_tech'>
              Appian aids in automating IT service processes, including incident
              management, change management, and service request fulfillment.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/utility.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Real Estate Management</h5>
            <p className='small_card_para_tech'>
              Organizations in real estate can use Appian for property
              management, lease tracking, and other related processes to enhance
              operational efficiency.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Appian
