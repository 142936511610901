import React from 'react'
import './sap.css'

const SAP = () => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='tech_container'>
        <div className='left_tech_container'>
          <h2 className='left_tech_container_heading'>
            SAP (Systems, Applications, and Products) is a leading enterprise
            resource planning (ERP) software that integrates various business
            processes.
          </h2>
          <p className='left_container_para'>
            SAP CRM (Customer Relationship Management) product offered by SAP is
            created to help with cost reduction and optimization of management
            decision-making. Also, it allows building a strong company's
            strategy and strengthening its competitiveness.
          </p>
          <p className='left_container_para'>
            SAP CRM is a unique solution that lets you unite employees,
            managers, clients, and partners of a company in a complete, looped
            cycle of processes and technologies, which contributes to effective
            customer relationship management.
          </p>
        </div>
        <div
          className='right_tech_container'
          style={{ backgroundImage: `URL('Images/img1.jpg')` }}
        ></div>
      </div>
      <div className='small_tech_container'>
        <h1>Benefit of Implementing SAP</h1>
        <div className='smalls_tech_container'>
          <div className='small_tech_card'>
            <img src='/Images/SAP/enterprise.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Enterprise Resource Planning
            </h5>
            <p className='small_card_para_tech'>
              SAP is widely used for end-to-end business process management,
              including finance, human resources, procurement, manufacturing,
              and supply chain.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/supply-chain.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Supply Chain Management</h5>
            <p className='small_card_para_tech'>
              Organizations utilize SAP for optimizing supply chain processes,
              managing inventory, demand planning, and logistics.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/customer.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Customer Relationship Management
            </h5>
            <p className='small_card_para_tech'>
              SAP CRM modules help businesses manage customer interactions,
              sales, and marketing activities, enhancing customer engagement and
              satisfaction.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/human.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Human Capital Management</h5>
            <p className='small_card_para_tech'>
              SAP HCM streamlines HR processes, including payroll, personnel
              administration, talent management, and workforce planning.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/finance.png' alt='finance' srcSet='' />
            <h5 className='small_card_para_head'>Financial Management:</h5>
            <p className='small_card_para_tech'>
              SAP's financial modules handle accounting, financial planning,
              budgeting, and reporting, providing a comprehensive solution for
              financial management.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/products.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Product Lifecycle Management
            </h5>
            <p className='small_card_para_tech'>
              SAP PLM supports the management of product development processes,
              from idea generation and design to manufacturing and maintenance.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/bussiness.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Business Intelligence and Analytics
            </h5>
            <p className='small_card_para_tech'>
              SAP provides powerful tools for business intelligence and
              analytics, allowing organizations to make informed decisions based
              on real-time data.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/manufacture.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Manufacturing Execution System
            </h5>
            <p className='small_card_para_tech'>
              SAP MES modules help monitor and control manufacturing operations
              on the shop floor, improving efficiency and quality.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/government.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Governance, Risk, and Compliance
            </h5>
            <p className='small_card_para_tech'>
              SAP GRC modules assist organizations in managing risks, ensuring
              compliance with regulations, and maintaining good governance
              practices.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/ecommerce.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              E-commerce and Omnichannel Retail
            </h5>
            <p className='small_card_para_tech'>
              SAP Commerce Cloud enables businesses to create and manage online
              stores, providing a seamless omnichannel retail experience.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/oilgas.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Oil and Gas Industry Solutions
            </h5>
            <p className='small_card_para_tech'>
              SAP offers industry-specific solutions for oil and gas companies,
              addressing challenges related to exploration, production, and
              supply chain management.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/utility.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Utilities Management</h5>
            <p className='small_card_para_tech'>
              SAP solutions cater to the unique needs of utility companies,
              managing processes related to energy production, distribution, and
              customer service.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/public.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Public Sector Management</h5>
            <p className='small_card_para_tech'>
              Governments and public sector organizations use SAP for managing
              public finance, citizen services, and administrative processes.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/healthcare.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Healthcare Management</h5>
            <p className='small_card_para_tech'>
              SAP provides healthcare solutions for managing patient data,
              hospital operations, and healthcare logistics, improving overall
              efficiency.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/travel.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Travel and Transportation</h5>
            <p className='small_card_para_tech'>
              SAP supports the travel and transportation industry with solutions
              for route optimization, logistics, and passenger experience
              management.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SAP
