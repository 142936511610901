import './App.css';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
// import NavbarPoper from './Components/Navbar/NavbarPoper/NavbarPoper';
// import HomeSlider from './Components/SliderHomepage/HomeSlider';
import { BrowserRouter as Router } from 'react-router-dom';
// import MultiSliders from './Screen/MultiCrousel/MultiSliders/MultiSliders';
import MainRoutes from './routes/MainRoutes';
import Contact from './Components/Contact/Contact';
import React from 'react';

function App() {

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  // const params = getSearchParamsForLocation();
  // console.log('params', params)
  React.useEffect(() => {

  }, [])
  return (

    <div className="App">
      <Router>
        <Navbar />
        <MainRoutes />
        <Contact />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
