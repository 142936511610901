import React from 'react'
// import './sap.css'

const Workday = () => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='tech_container'>
        <div className='left_tech_container'>
          <h2 className='left_tech_container_heading'>
            Workday is a cloud-based human capital management (HCM) and
            financial management software that provides solutions for various
            aspects of organizational management.
          </h2>
          <p className='left_container_para'>
            Workday revolutionizes human capital management by offering
            cloud-based solutions. It transforms traditional HR processes,
            providing real-time insights, and enhancing workforce efficiency and
            agility.
          </p>
          <p className='left_container_para'>
            Workday's cloud-based model offers cost-effectiveness, letting
            organizations pay for the services they use, reducing upfront
            expenses. It prioritizes security, ensuring data protection and
            compliance. Workday facilitates remote access, promoting
            collaboration, innovation, and efficiency in modern workplaces.
          </p>
        </div>
        <div
          className='right_tech_container'
          style={{ backgroundImage: `URL('Images/img1.jpg')` }}
        ></div>
      </div>
      <div className='small_tech_container'>
        <h1>Benefit of Implementing Workday</h1>
        <div className='smalls_tech_container'>
          <div className='small_tech_card'>
            <img src='/Images/SAP/enterprise.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Human Resources (HR) Management
            </h5>
            <p className='small_card_para_tech'>
              SAP is widely used for end-to-end business process management,
              including finance, human resources, procurement, manufacturing,
              and supply chain.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/supply-chain.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Payroll Management</h5>
            <p className='small_card_para_tech'>
              Workday streamlines payroll processes, ensuring accurate and
              timely payments, tax calculations, and compliance with regulatory
              requirements.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/customer.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Time and Attendance Tracking
            </h5>
            <p className='small_card_para_tech'>
              Organizations use Workday to track employee work hours, manage
              time-off requests, and generate accurate timesheets for payroll
              processing.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/human.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Benefits Administration</h5>
            <p className='small_card_para_tech'>
              Workday helps manage employee benefits programs, allowing
              organizations to configure and administer various benefit plans
              efficiently.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/finance.png' alt='finance' srcSet='' />
            <h5 className='small_card_para_head'>Financial Management</h5>
            <p className='small_card_para_tech'>
              Workday offers financial reporting, accounting, and analytics
              tools to manage budgeting, financial planning, and general ledger
              functions.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/products.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Learning Management</h5>
            <p className='small_card_para_tech'>
              Workday supports employee development through learning management
              features, including course management, training tracking, and
              certification management.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/bussiness.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Recruitment and Applicant Tracking
            </h5>
            <p className='small_card_para_tech'>
              Workday assists in the recruitment process by managing job
              postings, applicant tracking, and candidate evaluation,
              streamlining the hiring workflow.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/manufacture.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Performance Management</h5>
            <p className='small_card_para_tech'>
              It provides tools for setting and tracking performance goals,
              conducting performance reviews, and aligning individual goals with
              organizational objectives.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/government.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Workforce Planning</h5>
            <p className='small_card_para_tech'>
              Workday helps organizations analyze workforce trends, plan for
              future staffing needs, and make informed decisions about talent
              management.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/ecommerce.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Expense Management</h5>
            <p className='small_card_para_tech'>
              Workday simplifies expense reporting, reimbursement processes, and
              compliance with expense policies, enhancing financial control.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/oilgas.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Analytics and Reporting</h5>
            <p className='small_card_para_tech'>
              Organizations leverage Workday's analytics and reporting
              capabilities to gain insights into HR and financial data, enabling
              data-driven decision-making.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/utility.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Global Compliance</h5>
            <p className='small_card_para_tech'>
              Workday assists multinational companies in managing HR and
              financial processes in compliance with local regulations and
              global standards.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Workday
