import React from 'react'

const WhoOverview = () => {
  return (
    <>
      <div className='overview_container'>
        <p className='overview_header'>
          We’re in it for good, driving positive change for the benefit of all.
        </p>
        <p className='overview_content'>
          Our expert, committed team put our shared beliefs into action – every
          day. Together, we combine innovation
          {/* and collective knowledge to */}
          {/* create the extraordinary. */}
        </p>

        <button className='overview_what_btn'>Discover the difference</button>
      </div>
    </>
  )
}

export default WhoOverview
