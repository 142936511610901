import React from 'react'
// import './sap.css'

const AI = () => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='tech_container'>
        <div className='left_tech_container'>
          <h2 className='left_tech_container_heading'>
            Revolutionize Your World with Cutting-Edge AI Solutions:
            Transforming Ideas into Reality for a Smarter Tomorrow
          </h2>
          <p className='left_container_para'>
            Embark on a journey of digital transformation with our AI-driven
            solutions. Seamlessly integrating machine learning and automation,
            we empower businesses to navigate complexity with precision. From
            optimizing operations to enhancing customer experiences, our
            commitment to innovation ensures your success in the era of
            intelligent enterprise.
          </p>
          <p className='left_container_para'>
            Dive into the realm of possibilities with our advanced AI
            technologies. We specialize in crafting bespoke solutions tailored
            to your unique needs. Whether it's harnessing the potential of data
            or automating intricate processes, our expertise propels you towards
            a future where efficiency, intelligence, and adaptability converge
            for unparalleled success.
          </p>
        </div>
        <div
          className='right_tech_container'
          style={{ backgroundImage: `URL('Images/img1.jpg')` }}
        ></div>
      </div>
      <div className='small_tech_container'>
        <h1>AI Unleashed: Transforming Industries</h1>
        <div className='smalls_tech_container'>
          <div className='small_tech_card'>
            <img src='/Images/AI/natural.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Natural Language Processing (NLP)
            </h5>
            <p className='small_card_para_tech'>
              AI is used for language understanding, sentiment analysis, and
              chatbots, enhancing communication between machines and humans.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/image.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Image and Video Recognition
            </h5>
            <p className='small_card_para_tech'>
              AI enables the identification and analysis of visual content, used
              in facial recognition, object detection, and autonomous vehicles.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/install.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Recommendation Systems</h5>
            <p className='small_card_para_tech'>
              AI algorithms analyze user behavior to provide personalized
              recommendations, seen in streaming services, e-commerce platforms,
              and social media.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/health.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Healthcare Diagnostics</h5>
            <p className='small_card_para_tech'>
              AI aids in medical image analysis, disease detection, and
              personalized treatment plans, contributing to improved diagnostics
              and patient care.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/financial.png' alt='finance' srcSet='' />
            <h5 className='small_card_para_head'>Financial Fraud Detection:</h5>
            <p className='small_card_para_tech'>
              AI algorithms analyze transaction patterns and detect anomalies,
              enhancing fraud prevention in banking and finance.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/supplay.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Supply Chain Optimization</h5>
            <p className='small_card_para_tech'>
              AI optimizes supply chain processes by predicting demand,
              improving inventory management, and enhancing logistics.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/auto.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Autonomous Vehicles</h5>
            <p className='small_card_para_tech'>
              AI is crucial for self-driving cars, using sensors and algorithms
              to navigate and make real-time decisions.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/virtual.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Virtual Assistants</h5>
            <p className='small_card_para_tech'>
              AI powers virtual assistants like Siri, Alexa, and Google
              Assistant, providing voice-activated help and information.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/predictive.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Predictive Maintenance</h5>
            <p className='small_card_para_tech'>
              AI analyzes equipment sensor data to predict when maintenance is
              needed, reducing downtime and extending the life of machinery.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/education.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Education Technology</h5>
            <p className='small_card_para_tech'>
              AI is used in adaptive learning platforms, personalized tutoring,
              and educational content recommendation systems.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/cyber.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Cybersecurity</h5>
            <p className='small_card_para_tech'>
              AI enhances threat detection, identifying patterns and anomalies
              to protect systems from cyberattacks.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/AI/hr.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Human Resources</h5>
            <p className='small_card_para_tech'>
              AI aids in recruitment processes, automating candidate screening,
              and analyzing employee data for talent management.
            </p>
          </div>

          {/* <div className='small_tech_card'>
            <img src='/Images/SAP/public.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Public Sector Management</h5>
            <p className='small_card_para_tech'>
              Governments and public sector organizations use SAP for managing
              public finance, citizen services, and administrative processes.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/healthcare.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Healthcare Management</h5>
            <p className='small_card_para_tech'>
              SAP provides healthcare solutions for managing patient data,
              hospital operations, and healthcare logistics, improving overall
              efficiency.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/travel.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Travel and Transportation</h5>
            <p className='small_card_para_tech'>
              SAP supports the travel and transportation industry with solutions
              for route optimization, logistics, and passenger experience
              management.
            </p>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default AI
