import React from 'react'

const InsightsOverview = () => {
  return (
    <>
      <div className='what_industry_section'>
        <div className='industries_sections'>
          <ul>
            <li>About</li>
            <li>Career</li>
            <li>Contacts</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default InsightsOverview
