import React from 'react'
import { Link } from 'react-router-dom'

const WhatServices = () => {
  return (
    <>
      <div className='what_industry_section'>
        <div className='industries_sections'>
          <ul>
            {/* <li>Cloud</li> */}
            <Link to='/ai' className='Link_menu_item'>
              AI
            </Link>
            <Link to='/sap' className='Link_menu_item'>
              SAP
            </Link>
            <Link to='/workday' className='Link_menu_item'>
              {/* SAP */}
              Workday
            </Link>
            {/* <li>Consulting</li> */}
          </ul>
        </div>
        <div className='industries_sections'>
          <ul>
            {/* <li>Cybersecurity</li> */}
            <Link to='/appian' className='Link_menu_item'>
              Appian
            </Link>
            <Link to='/cloud-computing' className='Link_menu_item'>
              Cloud Computing
            </Link>
            {/* <Link to='/sap' className='Link_menu_item'>
              Enterprise Solutions
            </Link> */}
            {/* <li>Data and Analytics</li> */}
            {/* <li>Enterprise Solutions</li> */}
          </ul>
        </div>
        <div className='industries_sections'>
          <ul>
            {/* <li>IoT and Digital Engineering</li> */}
            {/* <Link to='/sap' className='Link_menu_item'>
              IoT and Digital Engineering
            </Link> */}
            {/* <Link to='/sap' className='Link_menu_item'>
              Brethair Interactive
            </Link> */}
            {/* <Link to='/sap' className='Link_menu_item'>
              Sustainability Services
            </Link> */}
            {/* <li>Brethair Interactive</li> */}
            {/* <li>Sustainability Services</li> */}
          </ul>
        </div>
      </div>
    </>
  )
}

export default WhatServices
