import React, { useState } from 'react'
import './Carousel.css' // Import your CSS file
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
// import { ArrowCircleLeftRounded } from '@mui/icons-material'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'

const cardData = [
  {
    id: 1,
    heading: `Comprehensive Support: Guiding You at Every Step`,
    desc: `At Brethair, we're committed to providing holistic support throughout the journey. Our experts are with you at each phase of solution implementation, from initial planning to post-implementation support. We offer services ranging from License Consultation and Planning to Design, Go Live, and beyond. With our unparalleled dedication, we ensure a seamless transition and continuous enhancement of your solutions.`,
    image: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e'
  },
  {
    id: 2,
    heading: `ERP & Finance Consulting: Unleashing Business Potential`,
    desc: `Unlock the full potential of your enterprise with our SAP consulting services. Brethair Private Limited excels in Finance transformation, Data Analytics, Intelligent Automation for Governance, Security and Control, and Integration. With our seasoned team and innovative solutions, we're dedicated to optimizing IT solutions for your business, ensuring substantial Return on Investment (ROI).
    `,
    image: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d'
  },
  {
    id: 3,
    heading: 'Cloud Computing: Transforming Your Digital Landscape',
    desc: `Experience the power of cloud computing with Brethair's expert guidance. Our Cloud Computing services provide seamless integration of cloud solutions into your business ecosystem. Whether it's public, private, or hybrid cloud models, we assist you in harnessing the scalability, flexibility, and efficiency that cloud computing offers`,
    image: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45'
  },
  {
    id: 4,
    heading:
      'Data Centre Services: Enhancing Infrastructure for Modern Computing',
    desc: 'Brethair Private Limited offers cutting-edge Data Centre Services that go beyond conventional approaches. Our team excels in redesigning data centers to align with cloud computing, visualization, automation, and advanced storage solutions. We specialize in optimizing data center infrastructure and operations, ensuring your organization is equipped to meet the demands of contemporary computing.',
    image: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c'
  },
  {
    id: 5,
    heading:
      'Welcome to Brethair Private Limited - Your Partner in Technical Consulting Services',
    desc: 'Brethair Private Limited is your trusted partner for delivering a comprehensive range of technical consulting services tailored to meet your business needs. Our expertise spans across various domains, including Data Centre Services, Cloud Computing, ERP & Finance Consulting, and Cyber Security Support. With a commitment to excellence, we empower businesses to thrive in the digital era.',
    image: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8'
  },
  {
    id: 6,
    heading: `Cyber Security Support: Safeguarding Your Digital Assets`,
    desc: `Security is paramount in the digital age, and Brethair is your ally in Cyber Security Support. We employ cutting-edge strategies to protect your digital assets from evolving threats. Our comprehensive approach encompasses risk assessment, incident response, and proactive security measures, ensuring your business remains resilient in the face of cyber challenges.
    `,
    image: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62'
  }
  // Add more cards as needed
]

const Crousel = () => {
  const [currentCard, setCurrentCard] = useState(0)
  const width = window.innerWidth

  const handleNext = () => {
    setCurrentCard(prev => (prev === cardData.length - 3 ? 0 : prev + 1))
  }

  const handlePrev = () => {
    setCurrentCard(prev => (prev === 0 ? cardData.length - 3 : prev - 1))
  }

  return (
    <div className='carousel-container'>
      <h3 className='brief_in_action'>Brief In Action</h3>
      <div className='carousel'>
        {width < 780
          ? cardData.slice(currentCard, currentCard + 1).map((card, index) => (
              <div
                key={card.id}
                className={`card ${index === 1 ? 'active' : ''}`}
                style={{
                  backgroundImage: `url(${card.image})`,
                  // opacity: '0.8',
                  background: 'linear-gradient(grey,black)',
                  borderRadius: '10px',
                  // border: '2px solid green',
                  filter: index !== 3 ? 'none' : 'blur(8px)'
                }}
              >
                <h3 className='crousel_card_heading'>{card?.heading}</h3>
                <p className='crousel_card_desc'>
                  {(card?.desc).slice(0, 150)}..
                </p>
                {/* kalta{card?.id} */}
                <button className='read_more_card'>READ MORE</button>
              </div>
            ))
          : cardData.slice(currentCard, currentCard + 4).map((card, index) => (
              <div
                key={card.id}
                className={`card ${index === 1 ? 'active' : ''}`}
                style={{
                  backgroundImage: `url(${card.image})`,
                  background: 'linear-gradient(grey, black)',
                  // opacity: '0.6',
                  borderRadius: '10px',
                  // border: '2px solid green',
                  filter: index !== 3 ? 'none' : 'blur(8px)'
                }}
              >
                <h3 className='crousel_card_heading'>{card?.heading}</h3>
                <p className='crousel_card_desc'>
                  {(card?.desc).slice(0, 150)}..
                </p>
                {/* kalta{card?.id} */}
                <button className='read_more_card'>READ MORE</button>
              </div>
            ))}
      </div>
      <button className='arrow prev' onClick={handlePrev}>
        {currentCard !== 0 && (
          <ArrowCircleLeftIcon sx={{ transform: 'scale(2.0)' }} />
        )}
      </button>
      <button className='arrow next' onClick={handleNext}>
        &#8250; <ArrowCircleRightIcon sx={{ transform: 'scale(2.0)' }} />
      </button>
    </div>
  )
}

export default Crousel
