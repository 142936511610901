import React from 'react'
import './Footer.css'

function Footer () {
  return (
    <footer>
      <div className='container'>
        <div className='logo_container'>
          <h3>Brethair </h3>
        </div>
        <div className='social_icon_container'>
          <img src='/Images/facebook.png' alt='' className='social_icon' />
          <img src='/Images/instagram.png' alt='' className='social_icon' />
          <img src='/Images/linkedin.png' alt='' className='social_icon' />
          <img src='/Images/twitter.png' alt='' className='social_icon' />
          {/* <img src="" alt="" className="social_icon" /> */}
        </div>
        <div className='center_tag_content'>
          <h6>©2023 Brethair Private Limited.All Rights Reserved</h6>
        </div>
        <div className='footer-nav'>
          <ul>
            <li>
              <a href='/'>Privacy Policy</a>
            </li>
            <li>
              <a href='/about'>Cookie Policy</a>
            </li>
            <li>
              <a href='/services'>Security Policy</a>
            </li>
            <li>
              <a href='/contact'>Disclaimer</a>
            </li>
          </ul>
        </div>
        {/* <div className='footer-contact'></div> */}
      </div>
    </footer>
  )
}

export default Footer
