import React from 'react'
// import './sap.css'

const CloudComputing = () => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='tech_container'>
        <div className='left_tech_container'>
          <h2 className='left_tech_container_heading'>
            Cloud computing: Internet-based computing, delivering services like
            storage and processing power, accessible remotely over networks.
          </h2>
          <p className='left_container_para'>
            Cloud computing revolutionizes IT by providing on-demand access to
            computing resources. It eliminates the need for heavy infrastructure
            investments, offering scalability and flexibility to meet changing
            business requirements effectively.
          </p>
          <p className='left_container_para'>
            Cost-effectiveness is a key advantage, with organizations paying
            only for the resources they use. This pay-as-you-go model reduces
            upfront costs, allowing businesses to scale based on demand. Cloud
            services include built-in security features, ensuring data
            protection. Additionally, cloud computing promotes collaboration,
            enabling access from anywhere and fostering innovation and
            efficiency.
          </p>
        </div>
        <div
          className='right_tech_container'
          style={{ backgroundImage: `URL('Images/img1.jpg')` }}
        ></div>
      </div>
      <div className='small_tech_container'>
        <h1>Benefit of Implementing Cloud Computing</h1>
        <div className='smalls_tech_container'>
          <div className='small_tech_card'>
            <img src='/Images/SAP/enterprise.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Infrastructure as a Service (IaaS
            </h5>
            <p className='small_card_para_tech'>
              Organizations can rent virtualized computing resources such as
              servers, storage, and networking, enabling scalable infrastructure
              without the need for physical hardware.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/supply-chain.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Platform as a Service (PaaS)
            </h5>
            <p className='small_card_para_tech'>
              Developers use cloud platforms to build, deploy, and scale
              applications without managing the underlying infrastructure,
              reducing development time and costs.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/customer.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Software as a Service (SaaS)
            </h5>
            <p className='small_card_para_tech'>
              Users access software applications over the internet, eliminating
              the need for local installations. Examples include email services,
              office suites, and customer relationship management (CRM) tools.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/human.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Data Storage and Backup</h5>
            <p className='small_card_para_tech'>
              Cloud storage services provide scalable and cost-effective
              solutions for storing and backing up data, ensuring accessibility
              and data durability.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/finance.png' alt='finance' srcSet='' />
            <h5 className='small_card_para_head'>Big Data Analytics</h5>
            <p className='small_card_para_tech'>
              Organizations leverage cloud platforms to process and analyze
              large volumes of data, utilizing services like Apache Hadoop and
              Apache Spark for scalable and distributed computing.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/products.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Internet of Things (IoT)</h5>
            <p className='small_card_para_tech'>
              Cloud computing supports IoT applications by providing scalable
              infrastructure for processing and analyzing data generated by
              connected devices.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/bussiness.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Content Delivery and Streaming
            </h5>
            <p className='small_card_para_tech'>
              Cloud-based Content Delivery Networks (CDNs) deliver web content,
              videos, and streaming services efficiently, improving user
              experience and reducing latency.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/manufacture.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Development and Testing</h5>
            <p className='small_card_para_tech'>
              Cloud platforms offer on-demand resources for development and
              testing purposes, allowing teams to quickly provision environments
              and scale as needed.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/government.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Machine Learning and AI</h5>
            <p className='small_card_para_tech'>
              Cloud services provide access to machine learning and artificial
              intelligence tools, enabling organizations to build and deploy
              models without significant infrastructure investment.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/ecommerce.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Collaboration and Communication
            </h5>
            <p className='small_card_para_tech'>
              Cloud-based collaboration tools facilitate real-time
              communication, document sharing, and project collaboration among
              teams, regardless of geographical locations.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/oilgas.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              Virtual Desktop Infrastructure (VDI)
            </h5>
            <p className='small_card_para_tech'>
              Cloud enables the deployment of virtual desktops, allowing users
              to access their desktop environments from any device with an
              internet connection.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/utility.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>
              E-commerce and Online Retail
            </h5>
            <p className='small_card_para_tech'>
              Cloud computing supports e-commerce platforms by providing
              scalable infrastructure, ensuring high availability during peak
              traffic times, and enabling efficient order processing.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/public.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Cybersecurity</h5>
            <p className='small_card_para_tech'>
              Cloud-based security solutions offer threat detection, encryption,
              and identity management services, enhancing the overall security
              posture of organizations.
            </p>
          </div>
          <div className='small_tech_card'>
            <img src='/Images/SAP/healthcare.png' alt='ai' srcSet='' />
            <h5 className='small_card_para_head'>Healthcare Data Management</h5>
            <p className='small_card_para_tech'>
              Cloud computing helps healthcare organizations store, manage, and
              analyze large volumes of patient data securely, facilitating
              collaboration and improving healthcare delivery.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CloudComputing
