import React from 'react'

const InsightsOverview = () => {
  return (
    <>
      {/* <div className='overview_container'>
        <p className='overview_header'>
          Extraordinary expertise leads to remarkable results.
        </p>
        <p className='overview_content'>
          We share news, insights, analysis and research – tailored to your
          unique interests – to help you deepen your knowledge and impact.
        </p>

        <button className='overview_what_btn'>Discover Expert Insights</button>
      </div> */}
      <div className='what_industry_section'>
        <div className='industries_sections'>
          <ul>
            <li>Events</li>
            <li>Newsroom</li>
            <li>Industry News</li>
            <li>Expert Opinion</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default InsightsOverview
