import * as React from 'react'
import './Contact.css'
import { PlainInput } from '../../Utils/Input/PlainInput'
import { useForm } from 'react-hook-form'
import { PlainTextArea } from '../../Utils/Input/PlainTextArea'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Link } from 'react-router-dom'

// let initialState = {
//   FIRSTNAME: '',
//   LASTNAME: '',
//   EMAIL: '',
//   PHONE: '',
//   MESSAGE: ''
// }
const Contact = () => {
  //   const [formdata, setFormdata] = React.useState(initialState)
  const [bodyFile, setBodyFile] = React.useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset
  } = useForm()

  const onSubmit = formdata => {
    // dispatch(contactDetails({ formdata, toast }))
    console.log('formdata', formdata)
    // axiosInstance.post('main/contact-us', formdata).then(res => {
    //   if (res.data.success == 1) {
    //     toast.success(res.data.message)
    //     setTimeout(() => {
    //       window.location.reload()
    //     }, 3000)
    //   } else {
    //     toast.error(res.data.message)
    //   }
    // })
  }
  React.useEffect(() => {
    reset({
      NAME: '',
      COMPANY: '',
      EMAIL: '',
      PHONE: '',
      MESSAGE: ''
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])
  return (
    <>
      <div className='footer_menu_contact_contaienr'>
        <div className='contact_container'>
          <div className='contact_container_left'>
            <h1 className='contact_header'>CONTACT US</h1>
            <h6 className='contact_happens_next'>WHAT HAPPENS NEXT</h6>
            <div className='contact_list'>
              <div className='item_number'>
                <div className='intem_list'>1</div>
                <hr className='line_draw' />
                <div className='intem_list'>2</div>
                <hr className='line_draw' />
                <div className='intem_list'>3</div>
              </div>
              <div className='item_contact_container'>
                <div className='item_contact'>
                  Our experts will reach out to you for a thorough discussion of
                  your specific needs and requirements.
                </div>
                <div className='item_contact'>
                  If your project involves sensitive information, we will sign
                  an NDA to ensure the confidentiality and security of your
                  data.
                </div>
                <div className='item_contact'>
                  Our team will prepare a customized project proposal with
                  scope, timeline, and budget for your informed decisions.
                </div>
              </div>
            </div>
          </div>
          <div className='contact_container_right'>
            <form
              action=''
              className='right_contact_form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <PlainInput
                label='Name'
                placeholder='Enter Name'
                errors={errors}
                name='NAME'
                validation={{
                  ...register('NAME', {
                    required: 'Phone is required'
                  })
                }}
              />
              <PlainInput
                label='Company'
                placeholder='Enter Company'
                errors={errors}
                name='COMPANY'
                validation={{
                  ...register('COMPANY', {
                    required: 'Company is required'
                  })
                }}
              />
              <PlainInput
                label='Email'
                placeholder='Enter Email'
                errors={errors}
                name='EMAIL'
                validation={{
                  ...register('EMAIL', {
                    required: 'Email is required'
                  })
                }}
              />
              <PlainInput
                label='Phone'
                placeholder='Enter Phone'
                errors={errors}
                name='PHONE'
                validation={{
                  ...register('PHONE', {
                    required: 'Phone is required',
                    minLength: {
                      value: 10,
                      message: 'Phone number must be 10 digits'
                    },
                    maxLength: {
                      value: 14,
                      message: 'Phone number must be 10 digits'
                    }
                  })
                }}
              />
              <PlainTextArea
                label='Your message'
                placeholder='Your message(optional)'
                errors={errors}
                name='MESSAGE'
                validation={{
                  ...register('MESSAGE', {
                    required: 'Message is required'
                  })
                }}
              />
              <Button
                sx={{
                  width: '100%',
                  height: '7vh',
                  justifyContent: 'space-around',
                  textTransform: 'none',
                  border: '1px dashed #456bb4',
                  background: '#e6f1fd',
                  color: '#000000',
                  '&:hover': {
                    backgroundColor: '#e6f1fd',
                    border: '1px dashed #456bb4'
                  }
                }}
                color='warning'
                variant='outlined'
                component='label'
              >
                <CloudUploadIcon
                  sx={{
                    color: bodyFile ? 'green' : '#456bb4'
                  }}
                />

                {bodyFile ? bodyFile.name : 'Drag and Drop or Browse File'}
                <input
                  hidden
                  accept='image/*'
                  multiple
                  type='file'
                  name='BODY_FILE'
                  onChange={e => setBodyFile(e.target.files[0])}
                />
              </Button>
              <button
                type='submit'
                className='sumit_contact_form'
                onSubmit={handleSubmit(onSubmit)}
              >
                CONFIRM
              </button>
            </form>
          </div>
        </div>
        <div className='footer_main_container'>
          <div className='footer_contact_container'>
            <div className='four_menu_footer'>
              <h5 className='four_menu_footer_heading'>SERVICES</h5>
              <ul>
                <li className='footer_menu_con_item'>
                  <Link to='/ai' className='Link_menu_item'>
                    AI
                  </Link>
                </li>
                <li className='footer_menu_con_item'>
                  <Link to='/appian' className='Link_menu_item'>
                    {/* AI */}
                    Appian
                  </Link>
                </li>
                <li className='footer_menu_con_item'>
                  <Link to='/workday' className='Link_menu_item'>
                    {/* AI */}
                    Workday
                  </Link>
                </li>
                <li className='footer_menu_con_item'>
                  <Link to='/cloud-computing' className='Link_menu_item'>
                    {/* AI */}
                    Cloud Computing
                  </Link>
                </li>
                <li className='footer_menu_con_item'>
                  <Link to='/sap' className='Link_menu_item'>
                    {/* AI */}
                    SAP
                  </Link>
                </li>
              </ul>
            </div>
            <div className='four_menu_footer'>
              <h5 className='four_menu_footer_heading'>SOLUTION</h5>
              <ul>
                <li className='footer_menu_con_item'>
                  Product Lifecycle Management
                </li>
                <li className='footer_menu_con_item'>Digital Supply Chain</li>
                <li className='footer_menu_con_item'>Financial Management</li>
                <li className='footer_menu_con_item'>Asset Management</li>
                <li className='footer_menu_con_item'>Spend Management</li>
                <li className='footer_menu_con_item'>HR Management</li>
                <li className='footer_menu_con_item'>
                  Sustainability Solutions
                </li>
              </ul>
            </div>
            <div className='four_menu_footer'>
              <h5 className='four_menu_footer_heading'>COMPANY</h5>
              <ul>
                <li className='footer_menu_con_item'>About</li>
                <li className='footer_menu_con_item'>Events</li>
                <li className='footer_menu_con_item'>Sustainability</li>
                <li className='footer_menu_con_item'>Newsroom</li>
                <li className='footer_menu_con_item'>Contacts</li>
                <li className='footer_menu_con_item'>Brethairglobal</li>
              </ul>
            </div>
            <div className='four_menu_footer'>
              {/* <ul> */}
              <div className='logos'>
                <img src='/Images/logo.jpeg' alt='Logo' />
                {/* <h6>Breathair</h6> */}
              </div>
              <br />
              <br />
              <p className='contact_detail'>
                {/* 801 Brickell Avenue, Suite 1970, Miami, FL 33131 • USA */}
                81,Boulevard Charles Livon,Marseille,Postal Code-13007,France
              </p>
              <p className='contact_detail'>
                Doddathoguru Village,Electonic City-Phase
                1,Bangalore,Karnataka,Pin-560100
              </p>
              <p className='contact_detail'>+33 6 01 1694 97 (France)</p>
              <p className='contact_detail'>+91 8093320124 (India)</p>

              <p className='contact_detail'>Info@brethairglobal.com</p>
              {/* </ul> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
