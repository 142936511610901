import React from 'react'
import './whatoverview.css'

const WhatOverview = () => {
  return (
    <>
      <div className='overview_container'>
        <p className='overview_header'>
          Brethair is here to make a difference through technology.
        </p>
        <p className='overview_content'>
          Leading the way in innovation for over 50 years, we build greater
          futures for businesses across multiple industries and 131 countries.
        </p>

        <button className='overview_what_btn'>Discover all solutions</button>
      </div>
    </>
  )
}

export default WhatOverview
