import React from 'react'
import './whatindustry.css'

const WhatIndustry = () => {
  return (
    <>
      <div className='what_industry_section'>
        <div className='industries_sections'>
          <ul>
            <li>Banking</li>
            <li>Capital Markets</li>
            <li>
              Consumer Goods and <br />
              Distribution
            </li>
            <li>
              Communications,Media, <br />
              and Information Services
            </li>
          </ul>
        </div>
        <div className='industries_sections'>
          <ul>
            <li>Education</li>
            <li>
              Energy,Resources, and <br /> Utilities
            </li>
            <li>Healthcare</li>
            <li>High Tech</li>
            <li>Insurance</li>
          </ul>
        </div>
        <div className='industries_sections'>
          <ul>
            <li>Life Sciences</li>
            <li>Manufacturing</li>
            <li>Public Services</li>
            <li>Retail</li>
            <li>Travel and Logistics</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default WhatIndustry
